/* 인트로 페이지 */
/* 인트로 페이지 - 로고 랩핑 */
@keyframes intro-preview-show-and-hide {
  70% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 인트로 페이지 - 로고 */
@keyframes intro-preview-logo {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 인트로 페이지 - 메인 */
@keyframes intro-main {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 인트로 페이지 - 메인 로고 뒷배경 애니메이션 */
@keyframes intro-main-logo-gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}
