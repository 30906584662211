@charset "UTF-8";

/* scheme */
:root {
  --color-bg: #ffffff;
  --color-f: #13182c;

  --z-index-1: 9;
  --z-index-2: 99;
  --z-index-3: 999;
  --z-index-4: 9999;
  --z-index-5: 99999;
  --z-index-6: 999999;
  --z-index-7: 9999999;
  --z-index-top: 99999999999999;

  --scrollbar-width: 5px;

  --box-shadow-default: 0 5px 12px 0 rgba(0, 0, 0, 0.1);

  --easing-function-1: cubic-bezier(0, 0.98, 0.09, 0.99);

  --radius-1: 6px;

  /* font-size */
  /* sm 14/16 */
  /* md 16/16 */
  /* lg 18/16 */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
}
[data-color-scheme='dark'] {
  --color-bg: #13182c;
  --color-f: #ffffff;
}
/* 시스템 모드 따라감 */
/* @media (prefers-color-scheme: dark) {
  :root {
    --color-bg: #13182c;
    --color-f: #ffffff;
  }
} */

#page {
  background-color: var(--color-bg);
  color: var(--color-f);

  width: 100%;
  max-width: 450px;
  height: 100vh;
  min-height: 100%;

  margin: 0 auto;
  position: relative;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

html,
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
pre,
form,
fieldset,
textarea,
p,
blockquote,
th,
td,
header,
footer,
figure,
label,
section,
main,
button,
input {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  font-size: 18px;
  letter-spacing: -0.05em;
  font-family:
    'Pretendard Variable',
    Pretendard,
    -apple-system,
    'Apple SD Gothic Neo',
    'Apple Color Emoji',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
@media screen and (max-width: 360px) {
  html,
  body {
    font-size: 16px;
  }
}
button,
input {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}
button {
  cursor: pointer;
}
ol,
ul {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
}
h2 {
  font-size: 1.8rem;
  font-weight: bold;
}
h3 {
  font-size: 1.6rem;
  font-weight: bold;
}
h4 {
  font-size: 1.4rem;
  font-weight: bold;
}
h5 {
  font-size: 1.2rem;
  font-weight: bold;
}
h6 {
  font-size: 1.1rem;
  font-weight: bold;
}
a {
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
}
hr {
  border-top: 1px solid #d9d9d9;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
table {
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
img {
  vertical-align: bottom;
  max-width: 100%;
}
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-weight: normal;
  font-style: normal;
}
caption,
th {
  text-align: left;
}
q:before,
q:after {
  content: '';
}
abbr,
acronym {
  border: 0;
}

div.notistack-SnackbarContainer {
  position: absolute;
}
.notistack-SnackbarContainer.z-index-overwrite {
  z-index: var(--z-index-top);
}

.tv-lightweight-charts canvas {
  letter-spacing: -0.1px;
}

.chart-legend-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}
@media (max-width: 430px) {
  .chart-legend-container {
    max-width: 72vw;
  }
}
